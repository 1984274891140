import React, { Component } from "react";
import conor from "../../content/assets/headshot_circle3.png";
import { FaTwitter, FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
import { SOCIAL } from "../constants";
import { Block, Row } from "glamor/jsxstyle";
import { Link } from "gatsby";

/**
 * Hero component
 *
 * The Hero component represents my intro on the index page that
 * welcomes users to my site and gives them the gist of what I'm here to offer
 **/
class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
      windowHeight: 0
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

    this.setState({ windowWidth, windowHeight });
  }

  render() {
    const styles = {
      showImg: this.state.windowWidth < 785
    };
    return (
      <>
        {styles.showImg ? (
          <div
            style={{
              display: "block",
              marginTop: "4.2rem",
              marginBottom: "4.5rem",
              fontSize: "28px",
              lineHeight: "2.8rem"
            }}
          >
            <img
              src={conor}
              alt="Conor"
              style={{
                width: "142px",
                height: "142px",
                minWidth: "142px"
              }}
            />
            <p
              style={{
                marginLeft: "0.8rem",
                lineHeight: "2.7rem"
              }}
            >
              <span
                className="conor-hero"
                style={{
                  fontWeight: 700,
                  letterSpacing: "0.03em"
                }}
              >
                <Link to={"/about"}> Hey, I'm Conor.</Link>
              </span>{" "}
              <font
                className="heroContent"
                style={{
                  fontWeight: 700,
                  letterSpacing: "0.03em"
                }}
              >
                I'm a product manager and data scientist.
              </font>
              <Block
                maxWidth="500px"
                fontSize="18px"
                maxWidth="36rem"
                paddingTop="5px"
                className="heroSocial"
              >
                <div>
                  <a
                    style={{
                      boxShadow: "none",
                      verticalAlign: "middle"
                    }}
                    href={SOCIAL.twitter.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="heroSocialTwitter"
                  >
                    <FaTwitter
                      size={16}
                      title={"Twitter"}
                      style={{
                        position: "relative",
                        top: "2px",
                        color: "#85BFF7",
                        paddingTop: "1.8rem"
                      }}
                    />{" "}
                    Twitter
                  </a>
                </div>
                <div>
                  <a
                    style={{
                      boxShadow: "none"
                    }}
                    href={SOCIAL.insta.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="heroSocialInstagram"
                  >
                    <FaInstagram
                      size={16}
                      title={"Instagram"}
                      style={{
                        position: "relative",
                        top: "2px",
                        color: "#EE7470"
                      }}
                    />{" "}
                    Instagram
                  </a>
                </div>
                <div>
                  <a
                    style={{
                      boxShadow: "none"
                    }}
                    href={SOCIAL.linkedin.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="heroSocialLinkedIn"
                  >
                    <FaLinkedin
                      size={17}
                      title={"LinkedIn"}
                      style={{
                        position: "relative",
                        top: "2px",
                        color: "#2966BC"
                      }}
                    />{" "}
                    LinkedIn
                  </a>
                </div>
                <div></div>
                <div>
                  <a
                    style={{
                      boxShadow: "none"
                    }}
                    href={SOCIAL.github.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="heroSocialGithub"
                  >
                    <FaGithub
                      size={16}
                      title={"Github"}
                      style={{
                        position: "relative",
                        top: "2px",
                        color: "#D4D4D4"
                      }}
                    />{" "}
                    Github
                  </a>
                </div>
              </Block>
            </p>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              marginTop: "4.2rem",
              marginBottom: "5rem",
              fontSize: "28px",
              lineHeight: "2.8rem"
            }}
          >
            {" "}
            <img
              src={conor}
              alt="Conor"
              style={{
                width: "142px",
                height: "142px",
                minWidth: "142px"
              }}
            />{" "}
            <p
              style={{
                marginLeft: "2.2rem",
                lineHeight: "2.7rem",
                marginTop: "5px"
              }}
            >
              <span
                className="conor-hero"
                style={{ fontWeight: 700, letterSpacing: "0.03em" }}
              >
                <Link to={"/about"}> Hey, I'm Conor.</Link>
              </span>{" "}
              <font
                className="heroContent"
                style={{
                  fontWeight: 700,
                  letterSpacing: "0.03em"
                }}
              >
                I'm a product manager and data scientist.
              </font>
              <Row
                component="nav"
                flex="1"
                display="flex"
                maxWidth="500px"
                fontSize="18px"
                maxWidth="36rem"
                paddingTop="5px"
                alignItems="left"
                justifyContent="flex-start"
                className="heroSocial"
              >
                <a
                  style={{
                    boxShadow: "none",
                    verticalAlign: "middle"
                  }}
                  href={SOCIAL.twitter.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="heroSocialTwitter"
                >
                  <FaTwitter
                    size={16}
                    title={"Twitter"}
                    style={{
                      position: "relative",
                      top: "2px",
                      color: "#85BFF7"
                    }}
                  />{" "}
                  Twitter
                </a>
                <a
                  style={{
                    boxShadow: "none",
                    marginLeft: "1.65rem"
                  }}
                  href={SOCIAL.insta.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="heroSocialInstagram"
                >
                  <FaInstagram
                    size={16}
                    title={"Instagram"}
                    style={{
                      position: "relative",
                      top: "2px",
                      color: "#EE7470"
                    }}
                  />{" "}
                  Instagram
                </a>
                <a
                  style={{
                    boxShadow: "none",
                    marginLeft: "1.65rem"
                  }}
                  href={SOCIAL.linkedin.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="heroSocialLinkedIn"
                >
                  <FaLinkedin
                    size={17}
                    title={"LinkedIn"}
                    style={{
                      position: "relative",
                      top: "2px",
                      color: "#2966BC"
                    }}
                  />{" "}
                  LinkedIn
                </a>
                <a
                  style={{
                    boxShadow: "none",
                    marginLeft: "1.65rem"
                  }}
                  href={SOCIAL.github.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="heroSocialGithub"
                >
                  <FaGithub
                    size={16}
                    title={"Github"}
                    style={{
                      position: "relative",
                      top: "2px",
                      color: "#D4D4D4"
                    }}
                  />{" "}
                  Github
                </a>
              </Row>
            </p>
          </div>
        )}
      </>
    );
  }
}

export default Hero;
