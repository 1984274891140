import React from "react";
import { Link } from "gatsby";

/**
 * Post preview component
 *
 * Shows the title of a blog post and a excerpt of some of the
 * test to give users an idea of what it's about.
 */
const Preview = ({ node, title, tag }) => {
  return (
    <article key={node.slug}>
      <header>
        <h2 className="previewHeader" style={{}}>
          <Link
            style={{
              boxShadow: "none",
              letterSpacing: "0.03em"
            }}
            to={`/blog/${node.slug}/`}
          >
            {title}
          </Link>
        </h2>
      </header>
      <p className="previewSubheader">
        {node.published_at} • {node.reading_time + 1} min •{" "}
        {tag == null ? "" : tag.name}
      </p>
      <div>
        <p
          className="previewContent"
          dangerouslySetInnerHTML={{
            __html: node.excerpt || node.description
          }}
        />
      </div>
    </article>
  );
};

export default Preview;
