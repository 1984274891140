import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Block } from "glamor/jsxstyle";

import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Preview from "../components/Preview";
import Hero from "../components/Hero";

/**
 * Main index page
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * Serves as the home page for my site with intro, latest posts, social links,
 * and subscribe place to subscribe.
 */

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.setState({ key: Math.random() });
  }

  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allGhostPost.edges;
    const schema = {
      "@context": "https://schema.org/",
      "@type": "Person",
      name: "Conor Dewey",
      description: data.site.siteMetadata.description,
      url: data.site.siteMetadata.siteUrl,
      image: "https://www.conordewey.com/headshot_rect.png",
      sameAs: [
        "https://twitter.com/cdeweyx",
        "https://www.instagram.com/cdeweyx/",
        "https://www.linkedin.com/in/conordewey/",
        "https://github.com/cdeweyx"
      ]
    };

    return (
      <Layout title={siteTitle}>
        <SEO
          title={siteTitle}
          description={data.site.siteMetadata.description}
          image={data.site.siteMetadata.image}
          schemaMarkup={schema}
        />
        <Hero />
        <Block>
          {" "}
          <div className="indexHeader">Recent Posts</div>
        </Block>
        {posts.map(({ node }) => {
          const title = node.title || node.slug;
          return <Preview node={node} title={title} tag={node.primary_tag} />;
        })}
        <Block>
          <section className="readMore">
            <Link to={"/blog"}>Read more posts →</Link>
          </section>
        </Block>
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        image
        description
      }
    }
    allGhostPost(sort: { order: DESC, fields: [published_at] }, limit: 3) {
      edges {
        node {
          title
          slug
          reading_time
          published_at(formatString: "MMM DD, YYYY")
          excerpt
          primary_tag {
            name
          }
        }
      }
    }
  }
`;
